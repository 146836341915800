<script setup lang="ts">
import { initApp } from './utils/app'

/// SET THE LOCALE /////////////////////////////////////////////////

useHead({
  htmlAttrs: {
    lang: '',
  },
})

/// Init the app
initApp()

/// Bootstrap

setTimeout(async () => {
  const longterm = useLongtermStorage()
  const authentication = useAuthentication()
  const currentLocale = useCurrentLocale()
  const tracking = useTracking()

  /// COOKIE CONCENT /////////////////////////////////////////////////

  function gtag() {
    pushToDataLayer(arguments)
  }

  // @ts-ignore
  gtag('consent', 'default', {
    ad_storage: longterm.personalCookiesAccepted ? 'granted' : 'denied',
    ad_user_data: longterm.personalCookiesAccepted ? 'granted' : 'denied',
    ad_personalization: longterm.personalCookiesAccepted ? 'granted' : 'denied',
    analytics_storage: longterm.analyticalCookiesAccepted
      ? 'granted'
      : 'denied',
  })

  /// TAG MANAGER ////////////////////////////////////////////////////

  pushToDataLayer({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })

  // init internal tracking
  tracking.init()

  // send initial datalayer (be sure to wait for authentication to be ready)
  await authentication.refresh()

  pushToDataLayer({
    site_version: 'new',
    hashed_user_email: authentication.getUser()?.hashedEmail,
    user_email: authentication.getUser()?.email,
    user_idh: authentication.getUser()?.idh,
    language: currentLocale,
    test_group_ma: authentication.getUser()?.testGroupMa,
    is_subscribed: authentication.getUser()?.isSubscribed ?? false,
    has_orders: authentication.getUser()?.hasOrders ?? false,
  })

  // SUBSCRIBE POPUP /////////////////////////////////////////////////

  if (globalThis.location?.href.includes('subscribe')) {
    showSubscribeBannerAfterCookieBannerCloses()
  }

  function showSubscribeBannerAfterCookieBannerCloses() {
    setTimeout(() => {
      if (longterm.cookieBannerClosed) {
        // @ts-ignore
        document.querySelector('.os-footer__subscribe ion-button')?.click()
      } else {
        showSubscribeBannerAfterCookieBannerCloses()
      }
    }, 1000)
  }
}, 0)
</script>

<template>
  <os-ssr-helper />
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<style>
:root {
  --background--accent: #01b3ff;
  --background--primary: #eff4f6;
  --background--transparent: #eff4f666;
  --background--blur: #eff4f6cc;
  --accent: #fb2e51;
  --accent--transparent: #fb2e5111;
  --default: #000;
  --header: #20276d;
  --default-transparent: #20276d33;
  --white: #fff;
  --white-transparent: #ffffffaa;
  --green: rgb(0, 133, 0);
  --background--green: rgb(196, 233, 196);

  --ion-font-family: 'Libre Franklin', sans-serif;
}

html,
body {
  color: var(--default);
  background-color: var(--background--primary);
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  overflow: hidden;
}

.gutter-above {
  margin-top: 1.5rem !important;
}

@media only screen and (min-width: 1024px) and (min-height: 900px) {
  .gutter-above {
    margin-top: 2rem !important;
  }
}

h1,
.h1 {
  color: var(--header);
  line-height: 1;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

.h1--black {
  font-weight: 900;
  letter-spacing: 0.01em;
  font-size: 1.25rem;
  line-height: 1.233;
}

@media only screen and (min-width: 1024px) and (min-height: 900px) {
  h1,
  .h1 {
    font-size: 1.5rem;
  }

  .h1--black {
    font-size: 2rem;
  }
}

h2,
.h2 {
  color: var(--header);
  line-height: 1.33;
  font-size: 0.85rem;
  font-weight: 700;
  margin: 0;
}

h3,
.h3 {
  color: var(--header);
  line-height: 1.33;
  font-size: 0.85rem;
  font-weight: 500;
  opacity: 0.75;
  margin: 0;
}

h4,
.h4 {
  line-height: 1.33;
  font-size: 1.25rem;
  font-weight: 500;
}

@media (max-width: 639px) {
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    font-size: 1rem;
    font-weight: normal;
  }
}

p,
.p {
  margin: 0;
  color: var(--default);
}

ul,
ol {
  margin: 0;
}

small,
.small {
  color: var(--header);
  font-size: 0.85rem !important;
  font-weight: normal;
  opacity: 0.75;
}

small.error,
.small.error {
  color: var(--accent);
}

ion-button {
  --border-radius: 0.25rem;
  margin: 0;
  user-select: none;
}

ion-button[expand='block'] {
  width: 100%;
}

ion-button:not([fill]) {
  --background: var(--accent);
  --background-activated: var(--accent);
  --background-hover: var(--accent);
  font-weight: bold;
}

ion-button[secondary]:not([fill]) {
  --background: var(--header);
  --background-activated: var(--header);
  --background-hover: var(--header);
}

ion-button[tertiary]:not([fill]) {
  --background: var(--accent--transparent);
  --background-activated: var(--accent--transparent);
  --background-hover: var(--accent--transparent);
  --color: var(--accent);
  font-weight: bold;
}

ion-button[fill='clear'] {
  --background: var(--transparent);
  --background-activated: var(--transparent);
  --background-hover: var(--transparent);
  --color: var(--header);
  --padding-start: 0;
  --padding-end: 0;
  font-weight: bold;
}

ion-button[fill='outline'] {
  --background: var(--white-transparent);
  --background-activated: var(--white-transparent);
  --background-hover: var(--white-transparent);
  --color: var(--header);
  --border-color: var(--white-transparent);
  --color-activated: var(--header);
}

@media (max-width: 639px) {
  ion-button {
    font-size: 0.85rem;
  }
}

ion-toolbar {
  --background: transparent;
  --background-activated: transparent;
  --background-hover: transparent;
  --ion-toolbar-color: var(--header);
}

ion-toolbar ion-button[fill='clear'] {
  --background: transparent;
  --background-activated: transparent;
  --background-hover: transparent;
}

ion-card {
  margin: 0;
  box-shadow: none;
  border-left: none;
  border-right: none;
  border-radius: 0.25rem;
}

.gap-none ion-card:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.gap-none ion-card:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

@media only screen and (min-width: 1024px) and (min-height: 900px) {
  ion-card {
    margin: 0;
    border: solid 2px var(--background--primary);
  }
}

ion-card-title {
  color: var(--header);
  font-size: 0.85rem;
  font-weight: 600;
}

@media only screen and (min-width: 1024px) and (min-height: 900px) {
  ion-card-title {
    font-size: 1rem;
  }
}

ion-popover {
  --width: 80%;
  --max-height: 66vh;
}

@media only screen and (min-width: 1024px) {
  ion-popover {
    --width: auto;
  }
}

ion-list {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

ion-list ion-item:last-child {
  --border-color: transparent;
}

ion-nav-link {
  cursor: pointer;
}

ion-skeleton-text {
  --border-radius: 0.25rem;
  --background-rgb: 116, 119, 120;
  margin: 0;
}

table {
  width: 100%;
}

table th {
  font-weight: normal;
  text-align: left;
}

table td {
  text-align: right;
}

table th,
table td {
  padding: 0.25rem 0;
  vertical-align: middle;
  color: var(--header);
}

table tbody tr:last-child th,
table tbody tr:last-child td {
  padding-bottom: 0.5rem;
}

table tfoot {
  border-top: solid 2px var(--background--primary);
}

table tfoot tr:first-child th,
tfoot tr:first-child td {
  padding-top: 0.5rem;
}

table tfoot th,
table tfoot td {
  font-weight: bold;
}

ion-label {
  white-space: nowrap;
}

ion-input,
ion-select,
.os-date-input,
ion-textarea {
  border: solid 2px var(--background--primary);
  border-radius: 0.25rem;
  --padding-start: 0.5rem !important;
  --padding-end: 0.5rem !important;
  background-color: white;
}

ion-textarea {
  overflow: hidden !important;
}

ion-input.required label > div:first-child::after,
ion-textarea.required label > div:first-child div::after,
ion-select.required label > div:first-child::after,
.os-date-input.required label > div:first-child::after,
.os-address-input.required label > div:first-child::after {
  content: '*' !important;
  color: var(--accent) !important;
  display: inline-block !important;
  margin-left: 0.15em !important;
}

ion-textarea {
  height: 22vh;
  max-height: 245px;
}

ion-searchbar {
  padding: 0 !important;
  min-height: 0 !important;
  --border-radius: 0.25rem !important;
  --background: var(--background--primary) !important;
}

ion-checkbox {
  --checkbox-background-checked: var(--accent);
  --border-color-checked: var(--accent);
  --border-radius: 0.25rem;
}

ion-checkbox[rounded] {
  --border-radius: 2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input,
textarea {
  font-family: sans-serif !important;
  font-weight: 400 !important;
}

ion-accordion ion-item {
  border-radius: 0.25rem;
}

ion-accordion ion-item h2 {
  max-width: calc(100vw - 100px);
  padding: 1rem 0;
}

ion-accordion [slot='content'] {
  padding: 1rem;
  padding-bottom: 3rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.grecaptcha-badge {
  display: none !important;
}

ion-toast {
  --background: var(--header);
  --button-color: var(--white);
  --color: var(--white);
}

ion-range {
  --bar-background: var(--accent--transparent);
  --bar-background-active: var(--accent);
}

:host(.range-label-placement-stacked) .label-text-wrapper {
  margin: 0 !important;
}

ion-router-outlet {
  inset: var(--ion-safe-area-top) 0 0 0;
}

ion-toggle {
  --track-background-checked: var(--accent);
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.body--cache-disabled::before {
  content: 'TEST';
  display: block;
  width: 100px;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  line-height: 1rem;
  font-size: 0.65rem;
  font-weight: bold;
  position: absolute;
  top: 60px;
  left: -12.5px;
  z-index: 9999999;
  letter-spacing: 0.5em;
  padding-left: 0.5em;
  background-color: var(--accent);
  color: var(--white);
  text-align: center;
  pointer-events: none;
  box-shadow: 0 0 1rem 0 var(--default-transparent);
}

.deal-image-ratio {
  aspect-ratio: 960/650 !important;
}
</style>
